import React from 'react';

import Layout from '../components/Layout';

//const IndexPage = () => (
 // <Layout fullMenu>
const IndexPage = () => <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Doing One New Thing a Month</h2>
        {/*<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>*/}
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>My Experience</h3>
          <p>
            I have been tutoring since 2014. I started as a peer tutor in high school where I taught science and math. 
            Now, I am tutoring high school and university students in science, math, programming, and engineering courses.
            I have worked with a wide range of students, from kindergarteners to university students, as well as students who are 
            looking to catch up with homework and students who are ahead of their class and looking for more challenges.
          </p>

          <p>
            McMaster students who book a session with me will gain access to my study cheat sheets for engineering and mechatronics courses.
          </p>

          <hr />

          <section>
            <h4>Want to book a session?</h4>
            <p>
              Feel free to <a href="mailto:laura@yang.ca">send me an email</a> to book a session or to ask about rates.<br></br>
            Limited availability, currently able to tutor McMaster students and students across Canada for online tutoring. 
            </p>
          </section>

          <hr />

          <section>
            <h4>Testimonials</h4>
            <p>
              "She was very patient, and created a very easygoing, positive atmosphere. She fully answered and 
              explained every question I had. Her teaching helped me understand concepts and questions that I was 
              completely lost in."    -Engineering student at McMaster (MATH 1ZA3, PHYSICS 1D03)
            </p>

            <p>
              "Great at making topics understandable when I was lost with content!"    -Math student at McMaster (MATH 2Z03)
            </p>

            <p>
              "Laura made my learning experience infinitely better. She patiently and clearly explained the steps to problems, 
              answered all my questions, and made the course material much easier to deal with and understand. She helped me 
              understand each problem and always made sure I understood it before moving on to the next topic. As a result of 
              her tutoring, I went from a failing grade in the class to a B on the final exam. I strongly recommend her 
              as a tutor."    -Civil Engineering student at McMaster (MATH 2ZZ3)
            </p>

            <p>
              "Laura helped me with math 2ZZ3, and I've never understood a university math course better. 
              She helped me understand how to solve questions as well as the underlying theory."    -Engineering Physics student at McMaster (MATH 2ZZ3)
            </p>
            
          </section>
        </div>
      </section>
    </article>
  </Layout>
//);

export default IndexPage;
